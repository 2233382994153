// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgTrash = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.375 4c0-.69.56-1.25 1.25-1.25h2.75c.69 0 1.25.56 1.25 1.25v1.25h-5.25zm-1.5 0v1.25H3a.75.75 0 0 0 0 1.5h18a.75.75 0 0 0 0-1.5h-4.875V4a2.75 2.75 0 0 0-2.75-2.75h-2.75A2.75 2.75 0 0 0 7.875 4m12.864 5.13a.75.75 0 0 0-1.478-.26l-1.995 11.346a1.25 1.25 0 0 1-1.23 1.034H7.964a1.25 1.25 0 0 1-1.231-1.034L4.739 8.87a.75.75 0 0 0-1.478.26l1.995 11.346a2.75 2.75 0 0 0 2.709 2.274h8.07a2.75 2.75 0 0 0 2.709-2.274z"
    />
  </svg>
);
export const TrashIcon = forwardRef(SvgTrash);
